const HowItWorks = () => {
  return (
    <section className="w-full pt-15 md:pt-[100px] pb-[50px] flex flex-col items-center text-dark-primary px-5">
      <h2 className="text-2xl sm:text-3xl md:text-4xl tracking-tight">
        How it <span className="font-display">Works</span>
      </h2>
      <div className=" w-full lg:w-[1060px] aspect-video mt-5 md:mt-7.5 rounded-xl md:rounded-2xl lg:rounded-[40px] overflow-hidden cursor-pointer">
        <img
          className="w-full h-full object-cover"
          src="https://storage.googleapis.com/myblankspace-prod/assets/vid.png"
          alt=""
        />
      </div>
    </section>
  );
};
export default HowItWorks;
