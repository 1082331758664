"use client"

import type React from "react"

import type { UseFormReturn } from "react-hook-form"
import { ICONS, Input } from "../ui/input"
import { useEffect, useRef, useState } from "react"
import { generateAddressOptions, generateLocalityOptions } from "@/src/lib/functions"
import useScreenSize from "@/src/hooks/useScreenSize"
import { cn } from "@/src/lib/utils"

interface Props {
  form: UseFormReturn<any>
  name?: string
  className?: string
  placeholder?: string
  inputSize?: "sm" | "md" | "lg"
  iconsClassName?: string
}

const LocalityInput: React.FC<Props> = ({
  form,
  name = "address",
  className = "",
  placeholder = "Enter Location",
  inputSize = "lg",
  iconsClassName = "",
}) => {
  const { width } = useScreenSize()
  const isSmall = width < 768
  const inputRef = useRef<HTMLInputElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const [options, setOptions] = useState<{ value: string; text: string }[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  // Handle outside click to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setOptions([])
        setIsFocused(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const onValueChange = async (value: string) => {
    form.setValue(name, value, { shouldValidate: true })

    if (value.trim() === "") {
      setOptions([])
      return
    }

    setIsLoading(true)

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(async () => {
      try {
        const newOptions = await generateLocalityOptions(value, true)
        setOptions(newOptions)
      } catch (error) {
        console.error("Error fetching address options:", error)
      } finally {
        setIsLoading(false)
      }
    }, 300)
  }

  const handleSelect = (value: string) => {
    form.setValue(name, value, { shouldValidate: true })
    setOptions([])
    inputRef.current?.blur()
    setIsFocused(false)
  }

  // Get field state from form
  const fieldState = form.getFieldState(name, form.formState)

  return (
    <div className="relative">
      <Input
        ref={inputRef}
        inputSize={isSmall ? "md" : inputSize}
        prefixIcon={ICONS.LOCATION}
        suffixIcon={isLoading ? ICONS.LOCATION : undefined} // Using CALENDAR as a loading indicator since there's no spinner icon
        className={cn("bg-white h-fit w-full", className)}
        placeholder={placeholder}
        value={form.watch(name) || ""}
        onChange={(e) => onValueChange(e.target.value)}
        onFocus={() => setIsFocused(true)}
        fieldState={fieldState}
        iconsClassName={iconsClassName}
      />

      {options.length > 0 && isFocused && (
        <div
          ref={dropdownRef}
          className="bg-white max-h-[240px] overflow-auto absolute z-[999] w-full border border-gray-200 rounded-md mt-1 shadow-lg"
        >
          {options.map((option, index) => (
            <div
              key={`${option.value}-${index}`}
              onClick={() => handleSelect(option.value)}
              className={cn(
                "p-3 cursor-pointer hover:bg-gray-50 flex items-center gap-2",
                "text-sm border-b border-gray-100 last:border-b-0",
                {
                  "py-6.25 px-3.75 text-lg": inputSize === "lg",
                  "py-5 px-3.75": inputSize === "md",
                },
              )}
            >
              <span
                className={cn(
                  "text-gray-500 flex-shrink-0",
                  {
                    "w-7.5 h-7.5": inputSize === "lg",
                    "w-5 h-5": inputSize === "md",
                    "w-4.5 h-4.5": inputSize === "sm",
                  },
                  iconsClassName,
                )}
              >
                {icons[ICONS.LOCATION]}
              </span>
              <span className="flex-1">{option.text}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

// Import the icons object from the Input component
import { icons } from "../ui/input"

export default LocalityInput

