import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {

}

const VuesaxBoldMessages = (props: Props) => (
  <svg
    width={30}
    height={31}
    viewBox="0 0 30 31"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path d="M19.4875 16.2159V21.3034C19.4875 21.7534 19.4375 22.1784 19.325 22.5659C18.8625 24.4034 17.3375 25.5534 15.2375 25.5534H11.8375L8.0625 28.0659C7.5 28.4534 6.75 28.0409 6.75 27.3659V25.5534C5.475 25.5534 4.4125 25.1284 3.675 24.3909C2.925 23.6409 2.5 22.5784 2.5 21.3034V16.2159C2.5 13.8409 3.975 12.2034 6.25 11.9909C6.4125 11.9784 6.575 11.9659 6.75 11.9659H15.2375C17.7875 11.9659 19.4875 13.6659 19.4875 16.2159Z" />
      <path d="M22.1875 20.2159C23.775 20.2159 25.1125 19.6909 26.0375 18.7534C26.975 17.8284 27.5 16.4909 27.5 14.9034V8.52844C27.5 5.59094 25.125 3.21594 22.1875 3.21594H11.5625C8.625 3.21594 6.25 5.59094 6.25 8.52844V9.46594C6.25 9.81594 6.525 10.0909 6.875 10.0909H15.2375C18.625 10.0909 21.3625 12.8284 21.3625 16.2159V19.5909C21.3625 19.9409 21.6375 20.2159 21.9875 20.2159H22.1875Z" />
    </g>
  </svg>
);

export default VuesaxBoldMessages;