"use client";

import FeaturedListing from "./featured";
import { Button } from "@/src/components/ui/button";
import { useAppDispatch, useAppSelector } from "@/src/redux/store";
import type { ListingsCategories } from "@/src/redux/listings/interface";
import { useEffect, useState } from "react";
import {
  getPublicListingsRequest,
  LISTING_VERIFICATION_STATUS,
  PUBLIC_LISTING_CATEGORIES,
} from "@/src/redux/listings/features";
import { useRouter } from "next/navigation";
import { useSession } from "next-auth/react"; // Import useSession from NextAuth

const FeaturedListings = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { publicListings } = useAppSelector((state) => state.listing);
  
  // Use NextAuth's useSession hook to check authentication status
  const { data: session, status } = useSession();
  
  const [listings, setListings] = useState<ListingsCategories>({});

  // Simplified useEffect to handle the listings data
  useEffect(() => {
    if (publicListings?.data.categories) {
      setListings(publicListings.data.categories);
    }
  }, [publicListings]);

  // Simplified useEffect to fetch the base listings
  useEffect(() => {
    const params = {
      location: "Nigeria", // Default location
      category: PUBLIC_LISTING_CATEGORIES.ALL,
      pagination: {
        page: 1,
        per_page: 8,
      },
      filters: {
        verification_status: LISTING_VERIFICATION_STATUS.APPROVED,
      },
    };

    dispatch(getPublicListingsRequest(params));
  }, [dispatch]);

  // Handle view more button click with NextAuth authentication check
  const handleViewMoreClick = () => {
    if (status === "authenticated") {
      // If user is authenticated, go directly to listings page
      router.push("/listings");
    } else {
      // If user is not authenticated, redirect to signin page with return URL
      router.push("/auth/signin?returnUrl=/listings"); // Updated to match your authOptions pages.signIn
    }
  };



  return (
    <section className="w-full pt-[50px] pb-[75px] border-b border-gray-muted flex flex-col items-center text-dark-primary px-5 lg:px-25">
      <h2 className="text-2xl sm:text-3xl md:text-4xl text-center tracking-tight">
        Featured <span className="font-display">Spaces</span>
      </h2>
      <div className="my-7.5 md:my-12.5 w-full grid-cols-2 md:grid-cols-3 grid gap-5 md:gap-12.5">
        {Object.entries(listings).map(([category, categoryListings]) =>
          categoryListings.map((listing, index) => (
            <FeaturedListing
              key={`${category}-${index}`}
              listing={{
                img: listing.images[0],
                description: listing.description,
                size: listing?.size,
                size_unit: listing?.size_unit,
                id: listing.id,
                price: listing?.base_price,
                interval: listing?.base_price_interval,
              }}
            />
          )),
        )}
      </div>
      <Button onClick={handleViewMoreClick} className="p-2.5 border border-gray-muted rounded-full" variant={"ghost"}>
        VIEW MORE ↗
      </Button>
    </section>
  );
};

export default FeaturedListings;