import { Button } from "@/src/components/ui/button";
import MainHeroContent from "./main";
import Nav from "./nav";
import VuesaxBoldMessages from "@/src/components/icons/Vuesax/vuesaxBoldMessages";

const Hero = () => {
  return (
    <section className="min-h-[800px] h-screen relative w-full">
      <div className="absolute w-full h-full left-0 top-0 z-0">
        <div className="bg-black bg-opacity-60 absolute w-full h-full"></div>
        <img
          className="w-full h-full object-cover"
          src="https://storage.googleapis.com/myblankspace-prod/assets/bg.png"
        />
      </div>
      <div className="z-10 relative">
        <Nav />
        <MainHeroContent />
      </div>
      <Button className="h-[60px] w-[60px] md:h-[70px] md:w-[70px] bg-black bg-opacity-10 backdrop-blur-lg border border-white border-opacity-10 mt-5 rounded-full absolute right-5 bottom-10 md:right-12.5 md:bottom-25">
        <VuesaxBoldMessages className="fill-white" />
      </Button>
      <div className="w-full absolute bottom-0 left-0 flex justify-between">
        <div className="w-12.5 h-6.25 bg-white"></div>
        <div className="w-12.5 h-6.25 bg-white"></div>
      </div>
    </section>
  );
};
export default Hero;
