import Hero from "@/src/components/page/home/hero";
import FeaturedListings from "@/src/components/page/home/featured";
import FAQs from "@/src/components/page/home/faqs";
import HowItWorks from "@/src/components/page/home/learn";
import WhyUs from "@/src/components/page/home/why";
import Footer from "@/src/components/page/home/footer";
import { AnimatePresence, motion } from "framer-motion";
import { useAppSelector } from "@/src/redux/store";

// Type definition for listings
export interface Listing {
  img: string;
  price: number;
  interval: "month" | "day" | "year";
  size: number;
  size_unit: "sqm" | "sqft";
  description: string;
}

// Mock listings data
export const mockListings: Listing[] = [
  {
    img: "https://storage.googleapis.com/myblankspace-prod/mock/listing%201.png",
    price: 4000,
    interval: "month",
    size: 500,
    size_unit: "sqm",
    description: "Large open space for meets. Banana island - Lagos, Nigeria",
  },
  {
    img: "https://storage.googleapis.com/myblankspace-prod/mock/listing%202.png",
    price: 40000,
    interval: "month",
    size: 400,
    size_unit: "sqm",
    description: "Beautiful minimal showroom Ekong road - Calabar",
  },
  {
    img: "https://storage.googleapis.com/myblankspace-prod/mock/listing%203.png",
    price: 100000,
    interval: "month",
    size: 200,
    size_unit: "sqm",
    description: "Casual seminar space Ikeja - Lagos, Nigeria",
  },
  {
    img: "https://storage.googleapis.com/myblankspace-prod/mock/listing%204.png",
    price: 100000,
    interval: "month",
    size: 200,
    size_unit: "sqm",
    description: "Large open space for meets. Banana island - Lagos, Nigeria",
  },
  {
    img: "https://storage.googleapis.com/myblankspace-prod/mock/listing%205.png",
    price: 100000,
    interval: "month",
    size: 200,
    size_unit: "sqm",
    description: "Casual seminar space Ikeja - Lagos, Nigeria",
  },
  {
    img: "https://storage.googleapis.com/myblankspace-prod/mock/listing%206.png",
    price: 100000,
    interval: "month",
    size: 200,
    size_unit: "sqm",
    description: "Beautiful minimal showroom Ekong road - Calabar",
  },
];

const HomePage = () => {
  const { homePageSearchClicked } = useAppSelector(state => state.utils);

  // Define animation variants
  const sectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  return (
    <main className="flex flex-col items-center overflow-hidden">
      <Hero />
      
      <AnimatePresence mode="wait">
        <motion.div
          key={homePageSearchClicked ? "search" : "default"}
          variants={sectionVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ duration: 0.5 }}
        >
          {/* Reorder components based on search state */}
          {homePageSearchClicked ? (
            <>
              <FeaturedListings />
              <HowItWorks />
            </>
          ) : (
            <>
              <HowItWorks />
              <FeaturedListings />
            </>
          )}
        </motion.div>
      </AnimatePresence>

      <FAQs />
      <WhyUs />
      <Footer />
    </main>
  );
};

export default HomePage;