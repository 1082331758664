"use client"
import { format, isBefore, startOfToday } from "date-fns"
import Icons8Search from "@/src/components/icons/icons8/icons8Search"
import { Button } from "@/src/components/ui/button"
import { ICONS, Input } from "@/src/components/ui/input"
import { Calendar } from "@/src/components/ui/calendar"
import { Popover, PopoverContent, PopoverTrigger } from "@/src/components/ui/popover"
import useScreenSize from "@/src/hooks/useScreenSize"
import LocalityInput from "@/src/components/custom/locality-input"
import { useForm } from "react-hook-form"
import { useAppDispatch } from "@/src/redux/store"
import { SethomePageSearchClicked } from "@/src/redux/utils"

// Only include the relevant parts of FilterState
interface SearchFilters {
  location?: string
  startDate?: Date
  endDate?: Date
}

const SearchListings = () => {
  const { width } = useScreenSize()
  const isSmall = width < 768
  const today = startOfToday()
  const dispatch = useAppDispatch()
  // Set up form with react-hook-form
  const { setValue, watch, handleSubmit } = useForm<SearchFilters>({
    defaultValues: {
      location: "",
      startDate: undefined,
      endDate: undefined,
    },
  })

  // Watch the form values
  const startDate = watch("startDate")
  const endDate = watch("endDate")

  // Handle start date change
  const handleStartDateChange = (date: Date | undefined) => {
    setValue("startDate", date)

    // If end date exists and is before the new start date, reset end date
    if (date && endDate && isBefore(endDate, date)) {
      setValue("endDate", undefined)
    }
  }

  // Handle end date change
  const handleEndDateChange = (date: Date | undefined) => {
    // Only allow end date to be set if it's after or equal to start date
    if (date && startDate && isBefore(date, startDate)) {
      return
    }
    setValue("endDate", date)
  }

  // Handle form submission
  const onSubmit = (data: SearchFilters) => {
    console.log("Search filters:", data)
    dispatch(SethomePageSearchClicked(true))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pt-7.5 md:pt-12.5 gap-1.25 flex flex-col items-center">
      <div className="flex md:flex-row flex-col gap-1.25 lg:gap-0 min-w-[300px]">
        {/* Location Input */}
        <LocalityInput form={{ setValue, watch, getFieldState: () => ({ error: undefined }) } as any} name="location" />

        {/* Start Date Calendar */}
        <Popover>
          <PopoverTrigger asChild>
            <div className="relative w-full md:w-[250px]">
              <Input
                inputSize={isSmall ? "md" : "lg"}
                prefixIcon={ICONS.CALENDAR}
                className="bg-black w-full bg-opacity-10 backdrop-blur-lg text-white h-fit placeholder:text-[rgba(255,255,255,0.6)] border-opacity-20 cursor-pointer"
                placeholder="Start Date"
                iconsClassName="fill-white"
                value={startDate ? format(startDate, "PPP") : ""}
                readOnly
              />
            </div>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={startDate}
              onSelect={handleStartDateChange}
              disabled={(date) => isBefore(date, today)}
              initialFocus
            />
          </PopoverContent>
        </Popover>

        {/* End Date Calendar */}
        <Popover>
          <PopoverTrigger asChild>
            <div className="relative w-full md:w-[250px]">
              <Input
                inputSize={isSmall ? "md" : "lg"}
                prefixIcon={ICONS.CALENDAR}
                className="bg-white h-fit w-full cursor-pointer"
                placeholder="End Date"
                value={endDate ? format(endDate, "PPP") : ""}
                readOnly
              />
            </div>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={endDate}
              onSelect={handleEndDateChange}
              disabled={(date) =>
                // Disable dates before today or before start date
                isBefore(date, today) || (startDate ? isBefore(date, startDate) : false)
              }
              initialFocus
            />
          </PopoverContent>
        </Popover>
      </div>
      <Button type="submit" className="w-20 h-20 md:w-25 md:h-25 primary-gradient mt-5 rounded-full">
        <Icons8Search className="fill-white h-7.5" />
      </Button>
    </form>
  )
}

export default SearchListings

