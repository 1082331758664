import SearchListings from "./search";

const MainHeroContent = () => {
  return (
    <div className="flex flex-col items-center pt-7.5  sm:pt-[50px] w-full lg:px-0 px-5">
      <div className="text-white text-xs md:text-sm text-opacity-70 px-5 py-2.5 bg-[#000] bg-opacity-10 backdrop-blur-lg rounded-full border-[rgba(255,255,255,0.1)] border">
        ✦ We are live in Nigeria today!
      </div>
      <h1 className="text-white mt-2.5 text-center text-4xl md:text-6xl lg:text-7xl tracking-tight">
        Book your <span className="font-display">Space</span> today <br />
        <span className="font-display">&</span> make that{" "}
        <span className="font-display">Dream</span> a reality.
      </h1>
      <p className="mt-2.5 md:mt-3.75 text-white text-center text-base lg:text-xl text-opacity-90">
        Rent pop up stores, events & hangout venues at an affordable rate, for
        as long as you need.
      </p>
      <SearchListings />
    </div>
  );
};
export default MainHeroContent;
