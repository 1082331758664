import { cn } from "@/src/lib/utils";
import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import { useState } from "react";

interface Props {
  listing: {
    img: string;
    id: string;
    price: number;
    interval: string;
    size: number;
    size_unit: string;
    description: string;
  };
  placeCutoutAtTop?: boolean;
}

const FeaturedListing: React.FC<Props> = ({
  listing,
  placeCutoutAtTop = false,
}) => {
  const { data: session } = useSession(); // Get authentication status
  const router = useRouter(); // For navigation
  const [isHovered, setIsHovered] = useState(false); // Track hover state

  const handleClick = () => {
    if (session) {
      // If authenticated, go to listing details
      router.push(`/listings/${listing.id}`);
    } else {
      // If not authenticated, redirect to sign-in
      router.push("/auth/signin");
    }
  };

  return (
    <div
      key={listing.id}
      className="group cursor-pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <figure className="aspect-square w-full relative overflow-hidden">
        <img
          src={listing.img}
          alt={listing.description}
          className={cn(
            "object-cover w-full h-full transition-all duration-300",
            {
              "filter brightness-75": isHovered, // Tint effect on hover
            }
          )}
        />
        <div
          className={cn(
            "flex items-center justify-between absolute left-0 w-full transition-all duration-300",
            {
              "top-0": placeCutoutAtTop,
              "bottom-0": !placeCutoutAtTop,
            }
          )}
        >
          <div className="w-2.5 lg:w-10 aspect-square bg-white"></div>
          <div className="w-2.5 lg:w-10 aspect-square bg-white"></div>
        </div>
        {/* Explore Listing Text */}
        <div
          className={cn(
            "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-lg md:text-xl lg:text-2xl font-serif font-bold tracking-wide transition-all duration-300",
            {
              "opacity-100": isHovered,
              "opacity-0": !isHovered,
            }
          )}
          style={{ textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)" }} // Optional elegance with shadow
        >
          Explore Listing
        </div>
      </figure>
      <div className="mt-7.5">
        <div className="flex flex-col md:flex-row md:gap-0 gap-1.25 justify-between text-1xs md:text-sm lg:text-base">
          <span className="text-dark-gray ellipsis-2 md:w-[50%]">
            {listing.description}
          </span>
          <span className="text-gray-secondary flex-1 text-nowrap md:text-right">
            {listing.size} {listing.size_unit}
          </span>
        </div>
        <span className="mt-1.25 md:mt-2.5 inline-block text-1xs md:text-sm text-dark-gray font-medium">
          NGN {listing.price} / {listing.interval}
        </span>
      </div>
    </div>
  );
};

export default FeaturedListing;